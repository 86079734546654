import React from "react"
import Layout from "../../components/Layout"
import Hero from "../../components/ui/Hero/Hero"
import ProductListing from "../../components/lists/ProductListing"
import InlineBreaker from "../../components/lists/InlineBreaker/InlineBreaker"
import { useStaticQuery, graphql } from "gatsby"

export default () => {
    const inlineBreakers = useStaticQuery(graphql`{
  allProduktListenStoererHJson(
    filter: {anzeige: {elemMatch: {pfad: {eq: "produkte/zubehoer"}}}}
  ) {
    edges {
      node {
        button {
          link
          text
        }
        kurzbeschreibung
        title
        icon {
          alt
          ratio
          src {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        anzeige {
          pfad
          positionen
        }
      }
    }
  }
}
`);

    return (
        <Layout
            crumbs={[
                { label: "Home", url: "" },
                { label: "Produkte", url: "produkte" },
                { label: "Zubehör", url: "zubehör" },
            ]}
            breadcrumbColor="light"
            headerColor="dark-blue"
            hasWave={false}
        >
            <Hero
                title="Unser Zubehör"
                subtitle="Hier finden Sie das passende Zubehör, sodass unsere Produkte Voll zur Geltung kommen. Es gibt Halterungen für Schilder, Montagehilfen und mehr."
                color="dark-blue"
                hasWaveBottom={["mobile"]}
            />

            <ProductListing 
                hasFilters={true} 
                filterByType="zubehoer" 
                inlineBreaker={inlineBreakers.allProduktListenStoererHJson.edges.map(({node}) => (
                    {
                        element: <InlineBreaker 
                            to={node.button.link} 
                            buttonText={node.button.text} 
                            title={node.title} 
                            kurzbeschreibung={node.kurzbeschreibung} 
                            icon={{src: node.icon.src, alt: node.icon.alt, ratio: node.icon.ratio}} 
                        />,
                        colspan: 2,
                        position: node.anzeige.filter(item => item.pfad === "produkte/zubehoer")[0].positionen,
                    }))
                }
            />
        </Layout>
    )
}
